h4 {
  margin: 10px 0;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
}

.search-days-container {
  padding: 5% 0% 5% 0%;
  width: 800px;
}

.search-days-nutrition-tracker-container {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  // margin: 5px auto;
  // height: 100%;
  padding-left: 0px;
}

.search-days-nutrition-tracker-separator-container {
  hr.rounded {
    border-top: 5px solid #bbb;
    border-radius: 5px;
    max-width: 100%;
  }

  padding-bottom: 30px;
}