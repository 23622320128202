ion-icon {
  font-size: 23px;
}

.nutrition-tracker-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nutrition-tracker-app-bar {
  cursor: pointer;
  z-index: 9999;
  overflow: "hidden";
}