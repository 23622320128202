body {
  margin: 0;
  // padding: 20px 40px;
  font-family: Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #d8f0f0 !important;
  background-color: 	#353a41 !important;

  // background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/rm283-nunny-047_1.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=7aa02bf31936d3ca207c60b71c3a5ff3");
  // background-image: url("https://wallpapers.com/images/hd/light-green-background-ficwi166srvzdxdw.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
