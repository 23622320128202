.consumption-info {
  // background-color: rgb(157, 198, 236);
  padding: 3% 5% 3% 5%;
  background-color: rgba(0, 230, 103, 0.15);
  box-shadow: 4px 4px rgba(0, 230, 103, 0.5);
  border-radius: 1.5rem;
  margin: 5% 5% 5% 5%;
  width: 80%;
  // height: 20%;
  text-align: center;
}